@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.prose-invert {
  color: white;
}

.prose-invert
  :where(ul > li):not(
    :where([class~="not-prose"], [class~="not-prose"] *)
  )::marker {
  color: white;
}

.bullet-color li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: red; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.bob-link-hover-underline::after {
  display: block;
  content: "";
  height: 2px;
  width: 0%;
  background-color: black;
  transition: all 0.3s;
}

.bob-link-hover-underline:hover::after {
  width: 100%;
}

#web-booker-widget {
  padding-left: 0;
}

.quotation-form {
  min-height: 522px;
  display: block;
  margin: 0 auto;
}

.quotation-form.loading {
  background: #eee;
}

.quotation-form .wrap .step1.step1-iframe {
  margin: 0 auto;
}

.quotation-form .wrap .step1__form {
  padding: 0;
}

.quotation-form .wrap .outlineButton .outlineButton__label {
  line-height: 1rem;
}

.quotation-form .wrap .primaryButton.step1__form__submit-button {
  font-size: 1.7rem;
}

.quotation-form .wrap .loadingButton.step1__loadingButton {
  font-size: 1.6rem;
}

.quotation-form .wrap h2 {
  font-size: 1.1rem;
}

.quotation-form .wrap .trainFlightModal button {
  font-size: 1.1rem;
}

.quotation-form .wrap .infoTransportModal {
  height: auto;
  padding-top: 2rem;
  padding-left: 15px;
}
.quotation-form .wrap .step1 .step1__datetime .datePicker .SingleDatePicker {
  padding-top: 0.25em;
}

.quotation-form
  .wrap
  .infoTransportModal
  .infoTransportModal__buttons__header
  .secondaryButton {
  font-size: 1rem;
}

.quotation-form
  .wrap
  .infoTransportModal
  .infoTransportModal__buttons
  .primaryButton {
  font-size: 1.6rem;
}

.quotation-form
  .wrap
  .infoTransportModal
  .infoTransportModal__meetingPoint__header {
  margin-top: 1.5rem;
}

.quotation-form
  .wrap
  .infoTransportModal
  .infoTransportModal__meetingPoint__header
  h2 {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.quotation-form .wrap .infoTransportModal .infoTransportModal__time__header {
  margin-top: 1.5rem;
}

.quotation-form .wrap .infoTransportModal .infoTransportModal__time__header h2 {
  font-size: 1.1rem;
  line-height: 1.8rem;
}

.quotation-form .wrap .infoTransportModal .infoTransportModal__buttons {
  margin-top: 0.3rem;
}

.quotation-form .wrap .step1 .step1__opacity {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0;
}

.quotation-form .wrap .step1 .step1__modal {
  top: 0;
  transform: translateY(0);
  padding: 10px;
  padding-right: 30px;
  padding-bottom: 20px;
}

@media screen and (max-width: 590px) {
  .quotation-form .wrap {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .quotation-form .wrap .step1__form {
    padding: 0;
  }

  .sticky {
    padding-top: 0;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
